<template>
  <div class="grade">
    <div class="condition">
      <el-form>
        <el-form-item label="分销员条件：">
          <el-radio-group v-model="ls_type" @change="changeCondition">
            <el-radio :label="1">推广金额满足条件</el-radio>
            <el-radio :label="2">自购金额满足条件</el-radio>
            <el-radio :label="3">推广客户数满足条件</el-radio>
            <el-radio :label="4">邀请分销员数满足条件</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="等级名称" align="center">
        <template v-slot="{ row }">
          <span v-if="row.is_edit">{{ row.name }}</span>
          <el-input v-else v-model="row.name" style="width: 150px" size="small"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="latitude" label="成为条件" width="350" align="center">
        <template v-slot="{ row }">
          <span v-if="row.is_edit">
            {{ row.condition }}
          </span>
          <div v-else class="rule">
            <div style="flex-direction: column" v-if="row.type == 0">
              <p>{{ row.condition }}</p>
              <p>
                注：可在
                <el-button type="text" @click="$emit('tabSwich', '1')">分销设置</el-button>
                中修改成为条件
              </p>
            </div>
            <div v-else-if="ls_type == 1">
              <p>推广金额满</p>
              <el-input v-model="row.value" size="small"></el-input>
              元
            </div>
            <div v-else-if="ls_type == 2">
              <p>自购金额满</p>
              <el-input v-model="row.value" size="small"></el-input>
              元
            </div>
            <div v-else-if="ls_type == 3">
              <p>客户数满</p>
              <el-input v-model="row.value" size="small"></el-input>
              人
            </div>
            <div v-else>
              邀请
              <el-select v-model="row.level_id" size="small">
                <el-option v-for="item in inviteList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
              满
              <el-input v-model="row.value" size="small"></el-input>
              人
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="scope" label="佣金比例设置" width="440" align="center">
        <template v-slot="{ row }">
          <span v-if="row.is_edit">
            <span v-if="setInfo.tier > 0">{{ `直接佣金比列（一级）${Number(row.commission_rate_stair)}%;` }}</span>
            <span v-if="setInfo.tier > 1">{{ `间接佣金比例（二级）${row.commission_rate_second}%;` }}</span>
            <span v-if="setInfo.tier > 2">{{ `次间接佣金比例（三级）${row.commission_rate_three}%;` }}</span>
          </span>
          <div v-else class="commission">
            <div style="line-height: 40px" class="item" v-if="setInfo.tier == 0">
              暂未开启分销，请先
              <el-button type="text" @click="$emit('tabSwich', '1')">去分销</el-button>
              设置
            </div>
            <div class="item" v-if="setInfo.tier > 0">
              <span>直接佣金比列（一级）</span>
              <el-input v-model="row.commission_rate_stair" size="small"></el-input>
              %
            </div>
            <div class="item" v-if="setInfo.tier > 1">
              <span>间接佣金比例（二级）</span>
              <el-input v-model="row.commission_rate_second" size="small"></el-input>
              %
            </div>
            <div class="item" v-if="setInfo.tier > 2">
              <span>次间接佣金比例（三级）</span>
              <el-input v-model="row.commission_rate_three" size="small"></el-input>
              %
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="等级购买权益" width="220" align="center">
        <template v-slot="{ row }">
          <span v-if="row.is_edit">享受商品折扣{{ row.discount }}折</span>
          <div v-else class="discount">
            商品享受折扣
            <el-input v-model="row.discount" size="small"></el-input>
            折
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-button v-if="!scope.row.is_edit" @click="levelSubmit" type="text" size="small">保存</el-button>
          <el-button v-if="scope.row.id && scope.row.is_edit" @click="edit(scope.row, scope.$index)" type="text" size="small">编辑</el-button>
          <el-button v-if="scope.row.type" @click="remove(scope.row.id, scope.$index)" type="text" size="small">{{ scope.row.is_edit ? '删除' : '取消' }}</el-button>
          <el-button v-else :disabled="scope.row.is_edit || scope.row.is_default" @click="remove(scope.row.id, scope.$index)" type="text" size="small">
            {{ scope.row.is_edit ? '删除' : '取消' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="add">
      <el-button @click="add" size="small" type="primary" icon="el-icon-plus">添加等级</el-button>
      <p>注意：分销佣金关系请确保在三级内</p>
      <p>
        分销结算日期为：售后维权期结束后到账
        <el-button type="text" @click="goSetOrder">设置售后维权期</el-button>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['ls_type', 'setInfo'],

  data() {
    return {
      list: [],
      listIndex: 0,
      inviteList: [],
      addInfo: {},
      editInfo: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    goSetOrder() {
      for (let i in this.list) {
        if (!this.list[i].id) return this.$message.warning('请先填写完当前等级设置');
      }
      this.$router.push('/set/setOrder');
    },
    changeCondition() {
      this.$axios
        .post(this.$api.distribution.editLevelType, {
          ls_type: this.ls_type,
        })
        .then(res => {
          if (res.code == 0) {
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    add() {
      if (this.list.every(item => item.is_edit)) {
        this.addInfo = {
          commission_rate_second: '',
          commission_rate_stair: '',
          commission_rate_three: '',
          discount: '',
          level_id: '',
          name: '',
          type: this.ls_type,
          value: '',
          is_edit: !1,
        };
        this.list.push(this.addInfo);
      } else {
        this.$message.warning('请先填写完当前等级设置');
      }
    },
    //处理默认等级
    handelDefaulGrade() {
      let setInfo = this.setInfo;
      let condition = null;
      if (setInfo.term == 1 || setInfo.term == 2) {
        condition = '表单申请(需要审核)';
      } else if (setInfo.term == 3) {
        condition = `消费金额满${setInfo.value}`;
      } else {
        condition = `购买指定商品`;
      }
      this.addInfo = {
        commission_rate_second: '',
        commission_rate_stair: '',
        commission_rate_three: '',
        condition,
        discount: '',
        level_id: '',
        name: '分销员',
        type: 0,
        value: '',
        is_edit: !1,
        is_default: !0,
      };
      this.list = [this.addInfo];
    },
    getList() {
      this.$axios
        .post(this.$api.distribution.levelList, {
          type: this.ls_type,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result;
            if (list.length) {
              let inviteList = JSON.parse(JSON.stringify(res.result));
              list.map(item => {
                item.is_edit = !0;
              });
              this.inviteList = inviteList;
              this.list = list;
            } else {
              this.handelDefaulGrade();
            }
            this.$emit('getGradeList', JSON.parse(JSON.stringify(list)), this.ls_type);
          }
        });
    },
    remove(id, index) {
      if (id) {
        if (this.list[index].is_edit) {
          this.$confirm('确认是否删除等级？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.$axios
              .post(this.$api.distribution.levelDel, {
                id: id,
              })
              .then(res => {
                if (res.code == 0) {
                  this.addInfo = {};
                  this.list.splice(index, 1);
                  this.$message.success('删除成功');
                } else {
                  this.$message.error(res.msg);
                }
              });
          });
        } else {
          this.list[index].is_edit = !0;
        }
      } else {
        this.addInfo = {};
        this.list.splice(index, 1);
      }
    },
    edit(row, index) {
      for (let i in this.list) {
        if (!this.list[i].id) return this.$message.warning('请先填写完当前等级设置');
      }
      this.list.map(item => (item.is_edit = !0));
      if (this.editInfo.type) {
        for (let i in this.editInfo) {
          this.list[this.listIndex][i] = this.editInfo[i];
        }
      }
      this.listIndex = index;
      this.editInfo = JSON.parse(JSON.stringify(row));
      this.addInfo = row;
      this.list[index].is_edit = !1;
    },
    levelSubmit() {
      try {
        let addInfo = this.addInfo;
        if (!addInfo.name) throw '请填写等级名称';
        if (!addInfo.value && addInfo.type) throw '请填写成为条件';
        let data = {
          name: addInfo.name,
          type: addInfo.type,
          level_id: addInfo.level_id > 0 ? addInfo.level_id : 0,
          value: addInfo.type ? addInfo.value : '',
          commission_rate_stair: addInfo.commission_rate_stair,
          discount: addInfo.discount,
        };
        if (this.setInfo.tier > 0) {
          if (!addInfo.commission_rate_stair) throw '请设置各级佣金比列';
        }
        if (this.setInfo.tier > 1) {
          data.commission_rate_second = addInfo.commission_rate_second;
          if (!addInfo.commission_rate_second) throw '请设置各级佣金比列';
        }
        if (this.setInfo.tier > 2) {
          data.commission_rate_three = addInfo.commission_rate_three;
          if (!addInfo.commission_rate_three) throw '请设置各级佣金比列';
        }
        if (!addInfo.discount) throw '请填写商品折扣';
        if (addInfo.id) data.id = addInfo.id;
        this.$axios.post(addInfo.id ? this.$api.distribution.levelEdit : this.$api.distribution.levelAdd, data).then(res => {
          if (res.code == 0) {
            this.getList();
            this.addInfo = {};
            this.editInfo = {};
            this.$message.success('保存成功');
          } else {
            this.$message.error(res.msg);
          }
        });
      } catch (e) {
        this.$message.warning(e);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #f56c6c;
  font-weight: 600;
  margin: 10px 0 20px 0;
}
.el-table {
  .discount {
    display: flex;
    align-items: center;
    .el-input {
      width: 70px;
      margin: 0 10px;
    }
  }
  .commission {
    .item {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        width: 155px;
      }
      &:last-child {
        margin: 0;
      }
      .el-input {
        width: 70px;
        margin: 0 10px;
      }
    }
  }
  .rule {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-input {
      width: 100px;
      margin: 0 10px;
    }
    .el-select {
      width: 120px;
      margin: 0 10px;
    }
  }
}
.add {
  margin-top: 30px;
  p {
    font-size: 14px;
    margin-top: 20px;
    color: #999;
  }
}
</style>
