<template>
  <div class="fieldset">
    <div class="content">
      <div class="set-text">
        <p>修改分销前端界面的文字</p>
        <div class="item">
          字段
          <el-input placeholder="推广" disabled></el-input>
          改为字段
          <el-input v-model="fieldss.promotion.alter_name"></el-input>
        </div>
        <div class="item">
          字段
          <el-input placeholder="分销" disabled></el-input>
          改为字段
          <el-input v-model="fieldss.distribution.alter_name"></el-input>
        </div>
        <div class="item">
          字段
          <el-input placeholder="佣金" disabled></el-input>
          改为字段
          <el-input v-model="fieldss.commission.alter_name"></el-input>
        </div>
        <div class="item">
          字段
          <el-input placeholder="元" disabled></el-input>
          改为字段
          <el-input v-model="fieldss.dollar.alter_name"></el-input>
        </div>
        <div class="item">
          字段
          <el-input placeholder="收益" disabled></el-input>
          改为字段
          <el-input v-model="fieldss.earnings.alter_name"></el-input>
        </div>
        <div class="item">
          字段
          <el-input placeholder="团队" disabled></el-input>
          改为字段
          <el-input v-model="fieldss.team.alter_name"></el-input>
        </div>
      </div>
      <div class="preview">
        <div class="back">
          <div class="title">
            我的
            <span class="color">{{ fieldss.promotion.alter_name ? fieldss.promotion.alter_name : fieldss.promotion.name }}</span>
          </div>
          <div class="user-info">
            <div class="avatar">
              <img src="https://thirdwx.qlogo.cn/mmopen/vi_32/0jef8dyLqYHhdbsMOFwOQicaiaxtxFeJQA4neNdY4GbMo6g6AckOsLpgCjeichO7mibtcllRybxn7Dg758u25gUTrw/132" />
            </div>
            <div class="name">
              <p>橘子吖</p>
              <div>
                <img src="@/assets/image/distribution/vip.png" alt="" />
                联合创始人
              </div>
            </div>
          </div>
          <div class="tips">目前为最高等级（{{ fieldss.promotion.alter_name ? fieldss.promotion.alter_name : fieldss.promotion.name }}加盟商再增加1人即可升级为经理）</div>
        </div>
        <div class="income">
          <div class="top">
            <div>
              <p>
                今日
                <span class="color">
                  {{ fieldss.earnings.alter_name ? fieldss.earnings.alter_name : fieldss.earnings.name }}（{{ fieldss.dollar.alter_name ? fieldss.dollar.alter_name : fieldss.dollar.name }}）
                </span>
              </p>
              <p>100.00</p>
            </div>
            <div>
              <p>
                累计
                <span class="color">
                  {{ fieldss.earnings.alter_name ? fieldss.earnings.alter_name : fieldss.earnings.name }}（{{ fieldss.dollar.alter_name ? fieldss.dollar.alter_name : fieldss.dollar.name }}）
                </span>
              </p>
              <p>896.45</p>
            </div>
          </div>
          <div class="bot">
            <span>
              可提现
              <span class="color">{{ fieldss.commission.alter_name ? fieldss.commission.alter_name : fieldss.commission.name }}</span>
              99.90
              <span class="color">{{ fieldss.dollar.alter_name ? fieldss.dollar.alter_name : fieldss.dollar.name }}</span>
            </span>
            <div>提现</div>
          </div>
        </div>
        <div class="share">
          <img src="@/assets/image/distribution/img.png" alt="" />
          <div class="title">
            <h1>{{ fieldss.promotion.alter_name ? fieldss.promotion.alter_name : fieldss.promotion.name }}商城海量佣金</h1>
            <p>邀请好友下单获得更多收益</p>
          </div>
          <div class="btn">
           {{ fieldss.promotion.alter_name ? fieldss.promotion.alter_name : fieldss.promotion.name }}收益
            <span>
              <i class="el-icon-arrow-right"></i>
            </span>
          </div>
        </div>   
        <div class="nav">
          <div class="item">
            <div class="left">
              <img src="@/assets/image/distribution/detail.png" alt="" />
              <span class="color">{{ fieldss.distribution.alter_name ? fieldss.distribution.alter_name : fieldss.distribution.name }}</span>
              明细
            </div>
            <div class="right">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <img src="@/assets/image/distribution/order.png" alt="" />
              <span class="color">{{ fieldss.distribution.alter_name ? fieldss.distribution.alter_name : fieldss.distribution.name }}</span>
              订单
            </div>
            <div class="right">
              15笔
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <img src="@/assets/image/distribution/log.png" alt="" />
              提现记录
            </div>
            <div class="right">
              15笔
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <img src="@/assets/image/distribution/tuidui.png" alt="" />
              我的
              <span class="color">{{ fieldss.team.alter_name ? fieldss.team.alter_name : fieldss.team.name }}</span>
            </div>
            <div class="right">
              15人
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <img src="@/assets/image/distribution/ka.png" alt="" />
              银行卡管理
            </div>
            <div class="right">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Preservation @preservation="saveField"></Preservation>
  </div>
</template>

<script>
import Preservation from '@/components/preservation';
export default {
  components: {
    Preservation,
  },
  props: {
    fieldss: {
      type: Object,
      default: () => ({
        promotion: {
          name: '推广',
          alter_name: '',
        },
        distribution: {
          name: '分销',
          alter_name: '',
        },
        commission: {
          name: '佣金',
          alter_name: '',
        },
        dollar: {
          name: '元',
          alter_name: '',
        },
        earnings: {
          name: '收益',
          alter_name: '',
        },
        team: {
          name: '团队',
          alter_name: '',
        },
      }),
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    saveField() {
      this.$axios.post(this.$api.distribution.editFields,{fields:this.fieldss}).then(res=>{
        if(res.code == 0){
          this.$message.success('保存成功')
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.fieldset {
  font-size: 14px;
  .content {
    padding-left: 30px;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    .set-text {
      & > p {
        margin-bottom: 20px;
      }
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .el-input {
          width: 150px;
          margin: 0 15px;
        }
      }
    }
    .preview {
      width: 375px;
      .color {
        color: #f56c6c;
      }
      .back {
        background: url('~@/assets/image/distribution/back.png') no-repeat;
        background-size: 100% 100%;
        height: 250px;
        overflow: hidden;
        padding-left: 15px;
        .title {
          text-align: center;
          font-size: 18px;
          color: #fff;
          margin-top: 37px;
        }
        .user-info {
          display: flex;
          align-items: center;
          margin-top: 30px;
          .avatar {
            border-radius: 50%;
            border: 2px solid #fff;
            margin-right: 15px;
            img {
              border-radius: 50%;
              width: 60px;
              height: 60px;
            }
          }
          .name {
            p {
              color: #fff;
              margin-bottom: 5px;
              font-size: 20px;
            }
            div {
              width: 80px;
              background: linear-gradient(90deg, #fff0ce, #ffe3a8);
              border-radius: 21px 4px 6px 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 17px;
              font-size: 11px;
              color: #c27422;
              img {
                width: 12px;
                height: 11px;
                margin-right: 2px;
              }
            }
          }
        }
        .tips {
          font-size: 12px;
          color: #fff;
          margin-top: 15px;
        }
      }
      .income {
        width: 345px;
        height: 130px;
        background: #ffffff;
        box-shadow: 0px 5px 16px 0px rgba(4, 0, 0, 0.02);
        border-radius: 10px;
        margin: 0 auto;
        position: relative;
        top: -55px;
        .top {
          border-bottom: 1px solid #eee;
          display: flex;
          height: 80px;
          div {
            flex: 1;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            &:nth-child(1) {
              border-right: 1px solid #eee;
            }
            p:nth-child(1) {
              color: #666;
              font-size: 11px;
              margin-bottom: 10px;
            }
            p:nth-child(2) {
              color: #333;
              font-size: 18px;
            }
          }
        }
        .bot {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 50px;
          padding: 0 20px;
          div {
            width: 50px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            background: #ff5e15;
            border-radius: 5px;
            color: #fff;
            font-size: 12px;
          }
        }
      }
      .share {
        height: 70px;
        width: 345px;
        margin: 0 auto;
        background: #f4f7fa;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 0 10px 0 18px;
        margin-top: -30px;
        img {
          width: 53px;
          height: 54px;
          margin-right: 10px;
        }
        .title {
          h1 {
            color: #333;
            font-size: 16px;
            margin-bottom: 6px;
          }
          p {
            color: #666;
            font-size: 12px;
          }
        }
        .btn {
          width: 85px;
          height: 19px;
          background: #ff5500;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 12px;
          margin-left: 20px;
          span {
            width: 12px;
            height: 12px;
            background: #fff;
            border-radius: 50%;
            color: #ff5500;
            margin-left: 5px;
          }
        }
      }
      .nav {
        padding: 0 15px;
        margin-top: 20px;
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #eee;
          height: 50px;
          .left {
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
          }
          .right {
            color: #999;
          }
        }
      }
    }
  }
}
</style>
